import { gql, useLazyQuery } from '@apollo/client'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useMemo } from 'react'
import BaseButton from 'src/components/Buttons/BaseButton'
import Carousel from 'src/components/Carousel/Carousel'
import LocationSearchCard from 'src/components/LocationSearchCard'
import { useGeolocation } from 'src/context/GeolocationProvider'
import useLogger from 'src/context/LoggerProvider/useLogger'
import useHasScrolled from 'src/hooks/useHasScrolled'
import LoadingRecommendedCard from '../../components/Loaders/LoadingRecommendedCard'
import { PAGE_SIZE } from '../../constants'
import { CARD_LOCATION_FIELDS } from '../../constants/fragments'
import useUserLocation from '../../hooks/useUserLocation'
import { IQuery, IQueryRecommendedLocationsArgs } from '../../types/graphql'
import { IQueryParams, RedirectUrls } from '../../types/types'
import CitiesSection from './CitiesSection'
import { MOCK_CITIES } from './constants'

const RECOMMENDED_LOCATIONS = gql`
  ${CARD_LOCATION_FIELDS}
  query RecommendedLocations(
    $pagination: PaginationInput!
    $searchInput: SearchLocationsInput!
  ) {
    recommendedLocations(pagination: $pagination, searchInput: $searchInput) {
      total
      hits {
        ...CardLocationFields
      }
      fallback
    }
  }
`
type IProps = {
  query?: Partial<IQueryParams>
}

const SpotsSection = ({ query }: IProps) => {
  const { center, setCenter, zoom } = useGeolocation()
  const logger = useLogger()
  const { placeDetails, loading } = useUserLocation()
  const hasScrolled = useHasScrolled()
  const { t } = useTranslation('landing')

  const [recommendedLocations, { data }] = useLazyQuery<
    Pick<IQuery, 'recommendedLocations'>,
    IQueryRecommendedLocationsArgs
  >(RECOMMENDED_LOCATIONS, {
    onError: (error) => {
      logger.error('Error retrieving locations.', {}, error)
    }
  })

  const closestCity = useMemo(() => {
    if (!center) return MOCK_CITIES[0].search
    const [closest] = MOCK_CITIES.map(({ lat, lng, ...rest }) => ({
      ...rest,
      distance: Math.sqrt((center.lat - lat) ** 2 + (center.lng - lng) ** 2)
    })).sort((a, b) => a.distance - b.distance)
    return closest.search
  }, [center])

  useEffect(() => {
    if (placeDetails && !loading) {
      const { lat, lng } = placeDetails
      setCenter({ lat, lng })
    }
  }, [placeDetails, loading, setCenter])

  useEffect(() => {
    if (loading || !hasScrolled) return

    recommendedLocations({
      variables: {
        pagination: { pageSize: 70, from: 0 },
        searchInput: { center, zoom, ...query }
      }
    })
  }, [loading, hasScrolled, center, zoom, recommendedLocations, query])

  const allLocations = data?.recommendedLocations?.hits
  const locations = allLocations?.slice(
    0,
    Math.min(allLocations.length, PAGE_SIZE)
  )

  return (
    <section className="flex w-full justify-center pb-[90px]">
      <div className="flex w-full max-w-screen-xxl flex-col items-center justify-center">
        <div className="flex w-full flex-col items-center justify-center px-5 md:flex-row md:items-start md:justify-between lg:px-7 xl:px-12 md-lg:flex-col md-lg:items-center">
          <div>
            <h3 className="w-full text-center text-[32px] font-semibold leading-9 text-black-400 md:text-left md-lg:text-center md-lg:text-[32px]">
              Spots {t('INDEX_RECOMMENDED.HEADING')}
            </h3>
            <p className="mt-2 text-center text-[15px] text-black-400 md:text-left md-lg:text-center">
              {t('INDEX_RECOMMENDED.SUBHEADING')}
            </p>
          </div>
          <div className="mt-[30px] flex w-full max-w-[728px] px-5 md:mt-0 md:w-full md:max-w-[782px] md:px-0 md-lg:mt-[30px] md-lg:max-w-[728px]">
            <CitiesSection closestCity={closestCity} />
          </div>
        </div>
        <div className="relative mt-11 block w-full overflow-hidden">
          {!locations ? (
            <>
              <LoadingRecommendedCard />
            </>
          ) : (
            <Carousel
              carouselOptions={{
                loop: true,
                containScroll: false,
                dragFree: true
              }}
              carouselOptionsDesktop={{ align: 'start' }}
              items={locations}
            >
              {({ item }) => (
                <LocationSearchCard
                  key={item.id}
                  location={item}
                  styleDefault={false}
                />
              )}
            </Carousel>
          )}
        </div>
        <div className="mb-[15px] flex w-full flex-col flex-wrap items-center justify-center gap-[23px] pt-11">
          <BaseButton
            fontSize={14}
            size="medium"
            width={140}
            color="primary"
            variant="outlined"
            rounded
            href={RedirectUrls.Search}
          >
            {t('INDEX_RECOMMENDED.BUTTON_SEARCH')}
          </BaseButton>
        </div>
      </div>
    </section>
  )
}

export default React.memo(SpotsSection)
